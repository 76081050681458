import { connect } from "react-redux"
import React, { Component } from "react"
import { pure, compose } from "recompose"
import { Link, graphql } from "gatsby"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"

import Layout from "../components/layout"
import SEO from "../components/seo"

import * as masterActions from "../actions/master"

type Props = {
  requestProcess: Function
  completeProcess: Function
  isProcessing: boolean
  classes: any
}

class PrivacyPage extends Component<Props> {
  render() {
    const { classes } = this.props

    return (
      <Layout>
        <SEO title="プライバシーポリシー" />
        <div className={classes.root}>
          <div className={classes.componentWrapper}>
            <section id="privacy pt20">
              <div className="title-wrapper pt20">
                <h1 className={classNames("title", classes.title)}>
                  Privacy Policy
                </h1>
              </div>
              <div className={classes.content}>
                <p className={classes.label}>個人情報保護法</p>
                当サイトの運営に際し、お客様のプライバシーを尊重し個人情報に対して十分な配慮を行うとともに、大切に保護し、適正な管理を行うことに努めております。
                <div className="mt40 mb20">【個人情報の利用目的】</div>
                <ol className={classes.listWrapper}>
                  <li className={classes.list}>
                    お客様のご要望に合わせたサービスをご提供するための各種ご連絡
                  </li>
                  <li className={classes.list}>
                    お問い合わせいただいたご質問への回答のご連絡
                  </li>
                </ol>
                <div className="mt40 mb40">
                  取得した個人情報は、ご本人の同意なしに目的以外では利用しません。
                  <br />
                  情報が漏洩しないよう対策を講じ、従業員だけでなく委託業者も監督します。
                  <br />
                  ご本人の同意を得ずに第三者に情報を提供しません。
                  <br />
                  法律に基づいて本人の同意なく個人情報を第三者提供する場合があります。
                  <br />
                  ご本人からの求めに応じ情報を開示します。
                  <br />
                  公開された個人情報が事実と異なる場合、訂正や削除に応じます。
                  <br />
                  個人情報の取り扱いに関する苦情に対し、適切・迅速に対処します。
                  <br />
                  利用目的を変更した場合、変更された利用目的について本人に通知いたします。
                  <br />
                  プライバシーポリシーの内容に関して本人の求めを受け付ける方法は下記の通りになります。
                </div>
                <div>
                  問い合わせ先
                  <br />
                  株式会社COZOU
                  <br />
                  EMAIL: info@cozou.co.jp
                </div>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    paddingTop: 50,
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
    },
  },
  componentWrapper: {
    margin: "0 auto",
    maxWidth: 980,
    padding: "40px 80px",
    backgroundColor: "#FFF",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 40px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 20px",
    },
  },
  title: {
    fontSize: 50,
    paddingBottom: 6,
    paddingRight: 40,
    color: "#333",
    borderBottom: "6px solid #333",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
      borderBottom: "4px solid #333",
    },
  },
  label: {
    fontSize: 20,
    fontWeight: "bold",
  },
  list: { marginBottom: 4 },
  content: {
    paddingTop: 50,
  },
})

function mapStateToProps(state: any) {
  const { isProcessing } = state.rootReducer.master

  return {
    isProcessing,
  }
}

const connector = connect(mapStateToProps, { ...masterActions })

export default compose(pure, withStyles(styles), connector)(PrivacyPage)
